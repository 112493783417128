import { Axios } from "utils";

export const authenticate = async (auth) => {
  const res = await Axios.post("/CambridgeCourse/SignIn", auth);
  return res.data;
};

export const getForgotPassword = async (email) => {
  const res = await Axios.post("/Auth/ForgotPassword/", email);
  return res.data;
};

export const resetPassword = async (auth) => {
  const res = await Axios.post("/Users/ResetPassword", auth);
  return res.data;
};

export const getPermission = async () => {
  const res = await Axios.get("/Users/permissions");
  return res.data;
};

export const generatePassword = async (payload) => {
  const res = await Axios.post("/Accounts/signup/complete", payload);
  return res.data;
};
