import { Button } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const DropZone = ({ setContentData, setFileUploadInfo }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFiles.length > 0) {
      setContentData((draft) => {
        draft.contentAttachment = acceptedFile;
        draft.fileName = acceptedFile.name;
        return draft;
      });
    }
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "document/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    setFileUploadInfo((draft) => {
      draft = { acceptedLength: acceptedFiles.length, rejectedLength: fileRejections.length };
      return draft;
    });
  }, [acceptedFiles, fileRejections]);

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li class="alert alert-success" key={file.path}>
      {file.path}
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li class="alert alert-danger" key={file.path}>
      {file.path}
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })} className="dropZone">
        <input {...getInputProps()} />
        <p style={{ marginBottom: 0 }}>Drag 'n' drop some files here, or click to select files</p>
        <Button color="primary mt-2 px-4 rounded-3" size="sm" className="m-2">
          Browse
        </Button>

        <ul className="dropzoneList"> {acceptedFileItems}</ul>
        <ul className="dropzoneList">{fileRejectionItems}</ul>
      </div>
    </>
  );
};
