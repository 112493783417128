import React from "react";
import {
  PageSection,
  ContentLayoutV2,
  PaginationTableLayout,
  StatusBadge,
  CustomSelectDropdown,
  CustomDatePicker,
} from "shared";
import { useBatch } from "../hooks";
import moment from "moment";

export const BatchList = () => {
  const { batchQuery, page, setPage, goToDetails } = useBatch();

  const { data, isLoading } = batchQuery;
  const { data: batches, totalPages, hasNext, hasPrevious, totalCount } = !isLoading && data;

  const ActionButtons = ({ row }) => {
    return (
      <button className="table-action-btn" onClick={() => goToDetails(row.original.batchId)}>
        <i className="bi bi-arrow-right"></i>
      </button>
    );
  };

  const DateFormatter = ({ value }) => {
    return moment(value).format("DD/MM/YYYY");
  };

  const StatusIndicator = ({ value }) => {
    return <StatusBadge value={value} />;
  };

  const columns = [
    {
      Header: "Batch Name",
      accessor: "name",
    },
    {
      Header: "Student Count",
      accessor: "studentCount",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: DateFormatter,
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: DateFormatter,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusIndicator,
    },
    {
      Header: "Actions",
      accessor: "studentId",
      id: "actions",
      Cell: ActionButtons,
    },
  ];

  const statusList = [
    {
      value: 0,
      label: "Active",
    },
    {
      value: 1,
      label: "Inactive",
    },
  ];

  return (
    <ContentLayoutV2 title={"Batch"} subtitle={"List"} breadcrumb={[]}>
      <PageSection>
        <div className="card-header d-flex align-items-center justify-content-between border-bottom mb-3">
          <h3 className="card-title mb-0">Reports</h3>
          <div className="mb-1 d-flex align-items-center justify-content-end"></div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center border-bottom mb-3 pb-3">
              <div className="task-filters d-flex justify-content-between align-items-center">
                <div className="task-filter-item me-3">
                  <CustomSelectDropdown
                    options={statusList}
                    onSelect={(e) => {
                      setPage((draft) => {
                        draft.status = e.target.value;
                        return draft;
                      });
                    }}
                    label="Status"
                    selected={page.status}
                  />
                </div>
                <div className="task-filter-item me-3">
                  <CustomDatePicker
                    placeholder="Start Date"
                    value={page.startDate}
                    onChange={(val) => {
                      setPage((draft) => {
                        draft.startDate = moment(val).format("YYYY-MM-DD");
                        return draft;
                      });
                    }}
                  />
                </div>
                <div className="task-filter-item me-3">
                  <CustomDatePicker
                    placeholder="End Date"
                    value={page.endDate}
                    onChange={(val) => {
                      setPage((draft) => {
                        draft.endDate = moment(val).format("YYYY-MM-DD");
                        return draft;
                      });
                    }}
                  />
                </div>
              </div>
              <div className="task-filter-reset">
                <button
                  className="fltr-clear-btn"
                  onClick={() => {
                    setPage((draft) => {
                      draft = {
                        pageIndex: 1,
                        pageSize: 50,
                        firstName: "",
                        status: "",
                        startDate: null,
                        endDate: null,
                      };
                      return draft;
                    });
                  }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
          <PaginationTableLayout
            columns={columns}
            data={batches ? batches : []}
            controlledPageCount={totalPages}
            controlledpageNo={page.pageIndex}
            controlledpageSize={page.pageSize}
            controlledFilterValue={page.firstName}
            setPage={setPage}
            page={page}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            totalCount={totalCount}
          />
        </div>
      </PageSection>
    </ContentLayoutV2>
  );
};
