import React from "react";
import Datetime from "react-datetime";
import "../styles/customDatePicker.style.css";
import moment from "moment";

export const CustomDatePicker = ({ value, placeholder, onChange, onBlur, ...rest }) => {
  const inputProps = {
    placeholder,
  };
  const formattedValue = value ? moment(value).format("DD/MM/YYYY") : null;

  return (
    <Datetime
      className="custom-date-picker"
      value={value ? value : placeholder}
      onChange={onChange}
      timeFormat={false}
      inputProps={inputProps}
      closeOnSelect={true}
      renderInput={(props) => {
        return <input {...props} value={formattedValue ? formattedValue : ""} />;
      }}
      {...rest}
    />
  );
};
