import { useMutation, useQueryClient, useQuery } from "react-query";
import { successMessage, errorMessage, successDeletedMessage } from "utils";
import {
  getContentList,
  uploadFileToBlob,
  deteleCambridgeCourseContent,
  GET_CONTENTLIST_BY_ID,
  addCambridgeCourseContent,
} from "..";
import { useImmer } from "use-immer";
import { useDelete } from "shared";

export const useContent = (load = false) => {
  const courseId = 36;
  const queryClient = useQueryClient();
  const [isContentModal, setIsContentModal] = useImmer(false);
  const [contentData, setContentData] = useImmer({
    courseId,
    title: null,
    fileName: "",
    contentAttachment: null,
  });

  const [isImageVideoModal, setIsImageVideoModal] = useImmer({ isOpen: false, url: "" });
  const [progressCount, setProgressCount] = useImmer(0);

  const { onDelete, deleteInfo, resetDeleteInfo } = useDelete();

  const imageVideoModalOperation = (url) => {
    setIsImageVideoModal((draft) => {
      draft.isOpen = !draft.isOpen;
      if (url) {
        draft.url = url;
      }
      return draft;
    });
  };

  const contentModalOperation = () => {
    setProgressCount((draft) => {
      draft = 0;
      return draft;
    });
    setIsContentModal((draft) => {
      draft = !draft;
      return draft;
    });
  };

  const contentList = useQuery([GET_CONTENTLIST_BY_ID, courseId], () => getContentList(courseId), {
    enabled: courseId !== 0,
  });

  const createCambridgeCourseContent = useMutation(addCambridgeCourseContent, {
    onError: () => {
      errorMessage();
    },
    onSuccess: () => {
      successMessage();
      queryClient.invalidateQueries([GET_CONTENTLIST_BY_ID]);
    },
    onSettled: () => {
      contentModalOperation();
      setContentData((draft) => {
        draft.courseId = courseId;
        draft.title = null;
        draft.contentAttachment = null;
        return draft;
      });
    },
  });

  const uploadCourseContent = useMutation(uploadFileToBlob, {
    onError: () => {
      errorMessage();
    },
    onSuccess: () => {},
    onSettled: () => {
      createCambridgeCourseContent.mutate(contentData);
    },
  });

  const removeCambridgeCourseContent = useMutation(deteleCambridgeCourseContent, {
    onError: (e) => {
      errorMessage();
    },
    onSuccess: () => {
      successDeletedMessage();
      queryClient.invalidateQueries([GET_CONTENTLIST_BY_ID]);
    },
    onSettled: () => {
      resetDeleteInfo();
    },
  });

  const onConfirmDelete = () => {
    removeCambridgeCourseContent.mutate(deleteInfo.id);
  };

  return {
    contentList,
    isContentModal,
    setIsContentModal,
    contentModalOperation,
    contentData,
    uploadCourseContent,
    setContentData,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    onConfirmDelete,
    imageVideoModalOperation,
    isImageVideoModal,
    progressCount,
    setProgressCount,
    createCambridgeCourseContent,
  };
};
