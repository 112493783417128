import React from "react";
import { Link } from "react-router-dom";
import Nergy360Logo from "../../assets/images/logo/360logo.png";
import SidebarMenu from "./SidebarMenu";
import { useSidebarContext } from "../contexts";

export function SideBar() {
  const { isSidebar, setIsSidebar } = useSidebarContext();
  return (
    <div id="sidebar" className={isSidebar ? "active" : ""}>
      <div className="sidebar-wrapper active">
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/dashboard">
                <img style={{ height: "54px" }} src={Nergy360Logo} alt="Logo" />
              </Link>
            </div>
            <div className="toggler">
              <a
                onClick={() => setIsSidebar(false)}
                style={{ cursor: "pointer" }}
                className="sidebar-hide d-xl-none d-block"
              >
                <i className="bi bi-x bi-middle" />
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <SidebarMenu title="Menu" />
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x" />
        </button>
      </div>
    </div>
  );
}
