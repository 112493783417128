import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
  Progress,
} from "reactstrap";
import { DropZone } from "shared";
import SimpleReactValidator from "simple-react-validator";
import { useImmer } from "use-immer";

export const ContentModal = ({
  isContentModal,
  toggle,
  uploadCourseContent,
  setContentData,
  contentData,
  progressCount,
  createCambridgeCourseContent,
  setProgressCount,
}) => {
  const [update, forceUpdate] = useState();
  const [fileUploadInfo, setFileUploadInfo] = useImmer({ acceptedLength: 0, rejectedLength: 0 });
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onInputChange = (e) => {
    setContentData((draft) => {
      draft.title = e.target.value;
    });
  };

  const onUpload = () => {
    if (validator.current.allValid()) {
      // createCambridgeCourseContent.mutate({
      //   content: contentData,
      //   onUploadProgress: (progressEvent) => {
      //     const percentageCompleted = Math.round(
      //       (progressEvent.loaded * 100) / progressEvent.total
      //     );
      //     setProgressCount((draft) => {
      //       draft = percentageCompleted;
      //       return draft;
      //     });
      //   },
      // });
      uploadCourseContent.mutate(contentData.contentAttachment);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    validator.current.hideMessages();
  }, [isContentModal]);

  return (
    <>
      <Modal isOpen={isContentModal}>
        <ModalHeader toggle={toggle}>Add Attachment</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="first-limit-vertical" className="mb-2">
                  Title*
                </label>
                <div className="col-12">
                  <Input
                    type="text"
                    id="first-limit-vertical"
                    className="form-control"
                    name="userLimit"
                    placeholder="Title"
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    invalid={validator.current.message("title", contentData.title, "required")}
                  />
                  <FormFeedback>
                    {validator.current.message("title", contentData.title, "required")}
                  </FormFeedback>
                </div>
              </div>
            </div>
            <div className="col-12">
              <DropZone setContentData={setContentData} setFileUploadInfo={setFileUploadInfo} />
              {progressCount > 0 && <Progress color="success" striped value={progressCount} />}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="reset"
            disabled={createCambridgeCourseContent.isLoading || uploadCourseContent.isLoading}
            className="btn btn-light-secondary me-3"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            disabled={
              createCambridgeCourseContent.isLoading ||
              uploadCourseContent.isLoading ||
              fileUploadInfo.rejectedLength > 0 ||
              fileUploadInfo.acceptedLength === 0
            }
            color="success"
            className="px-4"
            onClick={onUpload}
          >
            {createCambridgeCourseContent.isLoading || uploadCourseContent.isLoading
              ? "Saving.."
              : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
