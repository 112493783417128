import React from "react";
import "../styles/statusBadge.style.css";

export const StatusBadge = ({ value }) => {
  switch (value) {
    case 4:
      return <span className="badge-element deleted">Deleted</span>;
    case 3:
      return <span className="badge-element invited">Invited</span>;
    case 2:
      return <span className="badge-element pending-invited">Pending Invite</span>;
    case 1:
      return <span className="badge-element inactive">Inactive</span>;
    case 0:
      return <span className="badge-element active">Active</span>;
    default:
      return null;
  }
};
