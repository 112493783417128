import { BreadcrumbV2 } from "./BreadcrumbV2";
import "../styles/contentLayout.styles.css";

export const ContentLayoutV2 = ({ breadcrumb, children }) => {
  return (
    <>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            {breadcrumb && (
              <div className="col-12">
                <BreadcrumbV2 breadcrumb={breadcrumb} />
              </div>
            )}
          </div>
        </div>
        <section className="section">{children}</section>
      </div>
    </>
  );
};
