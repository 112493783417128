import React, { useState, useEffect } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { NavLink } from "react-router-dom";

const SidebarMenu = ({ title }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const menuData = [
      {
        id: 1,
        name: "Report",
        pathName: "/report",
        icon: "bi-grid-fill",
        show: true,
        children: [],
      },
      {
        id: 1,
        name: "Content",
        pathName: "/content",
        icon: "bi-grid-fill",
        show: true,
        children: [],
      },
    ];

    const returnMenuItem = (item, i) => {
      let menuItem;

      if (item.children.length === 0) {
        menuItem = (
          <li className="sidebar-item" key={i}>
            <NavLink
              to={item.pathName}
              className="toggler sidebar-link"
              id={`toggle-menu-item-${item.id}`}
            >
              <i className={`bi ${item.icon}`} />
              <span>{item.name}</span>
            </NavLink>
          </li>
        );
      } else {
        let menuItemChildren = item.children.map((item, i) => {
          let menuItem = returnMenuItem(item, i);
          return menuItem;
        });

        menuItem = (
          <li key={i} className="sidebar-item has-sub">
            <NavLink
              to={item.pathName}
              className="toggler sidebar-link"
              id={`toggle-menu-item-${item.id}`}
            >
              <i className={`bi ${item.icon}`} />
              <span>{item.name}</span>
            </NavLink>

            <UncontrolledCollapse
              tag="ul"
              className="children ni-submenu"
              toggler={`#toggle-menu-item-${item.id}`}
            >
              {menuItemChildren}
            </UncontrolledCollapse>
          </li>
        );
      }
      return menuItem;
    };

    const load = async () => {
      setLoading(false);
      let menuItems = menuData.map((item, i) => {
        if (item.show) {
          let menuItem = returnMenuItem(item, i);
          return menuItem;
        }
      });
      setItems(menuItems);
    };

    if (loading) {
      load();
    }
  }, [loading]);

  return (
    <>
      <ul className="menu">
        <li className="sidebar-title">{title}</li>
        {items}
      </ul>
    </>
  );
};

export default SidebarMenu;
