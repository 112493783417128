import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useGeneratePassword } from "..";
import Nergy360Logo from "../../assets/images/logo/360logo.png";
import SimpleReactValidator from "simple-react-validator";
import { Input, FormFeedback, Spinner } from "reactstrap";

export function GeneratePassword() {
  const { generatePasswordAuth, generatePasswordState, setGeneratePasswordState } =
    useGeneratePassword();
  const [update, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const onsubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (validator.current.allValid()) {
      generatePasswordAuth.mutate(generatePasswordState.credential);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setGeneratePasswordState((draft) => {
      draft.credential[name] = value;
      return draft;
    });
  };

  return (
    <div>
      <div id="auth">
        <div className="row h-100">
          <div className="col-lg-5 col-12">
            <div id="auth-left">
              <div className="auth-logo">
                <a href="/">
                  <img style={{ height: "68px" }} src={Nergy360Logo} alt="Logo" />
                </a>
              </div>
              <h1 className="auth-title">Generate Password.</h1>
              <p className="auth-subtitle mb-5">Create password for your account.</p>
              <form>
                <div className="form-group position-relative has-icon-left mb-4">
                  <Input
                    type="password"
                    className="form-control form-control-xl"
                    placeholder="New Password"
                    name="password"
                    value={generatePasswordState.credential.password}
                    disabled={generatePasswordAuth.isLoading}
                    onChange={onHandleChange}
                    invalid={validator.current.message(
                      "New Password",
                      generatePasswordState.credential.password,
                      "required"
                    )}
                  />
                  <FormFeedback>
                    {validator.current.message(
                      "New Password",
                      generatePasswordState.credential.password,
                      "required"
                    )}
                  </FormFeedback>
                  <div className="form-control-icon">
                    <i className="bi bi-shield-lock" />
                  </div>
                </div>
                <div className="form-group position-relative has-icon-left mb-4">
                  <Input
                    type="password"
                    className="form-control form-control-xl"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={generatePasswordState.credential.confirmPassword}
                    disabled={generatePasswordAuth.isLoading}
                    onChange={onHandleChange}
                    invalid={validator.current.message(
                      "Confirm Password",
                      generatePasswordState.credential.confirmPassword,
                      "required"
                    )}
                  />
                  <FormFeedback>
                    {validator.current.message(
                      "Confirm Password",
                      generatePasswordState.credential.confirmPassword,
                      "required"
                    )}
                  </FormFeedback>
                  <div className="form-control-icon">
                    <i className="bi bi-shield-lock" />
                  </div>
                </div>
                <button
                  className="btn btn-block btn-lg shadow-lg mt-5 btn-success"
                  disabled={generatePasswordAuth.isLoading}
                  onClick={(e) => {
                    onsubmit(e);
                  }}
                >
                  {generatePasswordAuth.isLoading ? (
                    <Spinner type="border" color="light" />
                  ) : (
                    "Create password"
                  )}
                </button>
              </form>
              <div className="text-center mt-5 text-lg fs-4">
                <p className="text-gray-600">
                  Already have an account?{" "}
                  <Link className="font-bold" to="/">
                    Login
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 d-none d-lg-block">
            <div id="auth-right">
              <div className="fade-over"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
