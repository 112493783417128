import React, { createContext, useContext, useState, useEffect } from "react";

export const SidebarContext = createContext();

export const SidebarScope = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState();
  const [windowWidth, setWindowWidth] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth } = window;
    return innerWidth;
  }

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("sb-status"));
    if (localData) {
      const sideBarMode = localData || true;
      setIsSidebar(sideBarMode);
    }

    function handleWindowResize() {
      setWindowWidth(getWindowSize());
    }

    if (windowWidth < 1200) {
      localStorage.setItem("sb-status", false);
      setIsSidebar(false);
    } else {
      localStorage.setItem("sb-status", true);
      setIsSidebar(true);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [setIsSidebar, windowWidth]);

  return (
    <SidebarContext.Provider
      value={{
        isSidebar,
        setIsSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
