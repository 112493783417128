import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useImmer } from "use-immer";
import { useDebounce } from "../hooks/useDebounce";
import "../styles/tablePagination.styles.css";

export const TablePaginationControlled = (props) => {
  const {
    page,
    setPage,
    pageIndex,
    controlledPageCount,
    hasPrevious,
    hasNext,
    pageOptions,
    pageSize,
    totalCount,
  } = props;
  const [pages, setPages] = useImmer([]);
  const debouncedPages = useDebounce(pages, 200);
  const startDataIndex = pageIndex - 1;
  const startCount = startDataIndex * pageSize + 1;

  React.useEffect(() => {
    if (pageIndex === 0 || pageIndex === 1) {
      setPages((draft) => {
        draft = [...pageOptions].slice(0, 3);
        return draft;
      });
    } else {
      setPages((draft) => {
        draft = [...pageOptions].slice(pageIndex - 2, pageIndex + 2);
        return draft;
      });
    }
  }, [pageIndex, pageOptions]);

  const firstDoubleArrow = () => (
    <button
      onClick={() =>
        setPage((draft) => {
          if (hasPrevious) {
            draft.pageIndex = 1;
          }
          return draft;
        })
      }
      disabled={!hasPrevious}
      className="page-link"
      aria-label="First"
    >
      <span aria-hidden="true" className="bi-chevron-double-left"></span>
    </button>
  );

  const previousArrow = () => (
    <button
      onClick={() =>
        setPage((draft) => {
          if (hasPrevious) {
            draft.pageIndex = draft.pageIndex - 1;
          }
          return draft;
        })
      }
      disabled={!hasPrevious}
      className="page-link"
      aria-label="Previous"
    >
      <span aria-hidden="true" className="bi-chevron-left"></span>
    </button>
  );

  const nextArrow = () => (
    <button
      onClick={() =>
        setPage((draft) => {
          if (hasNext) {
            draft.pageIndex = draft.pageIndex + 1;
          }
          return draft;
        })
      }
      disabled={!hasNext}
      className="page-link"
      aria-label="Next"
    >
      <span aria-hidden="true" className="bi-chevron-right"></span>
    </button>
  );

  const lastDoubleArrow = () => (
    <button
      onClick={() =>
        setPage((draft) => {
          if (hasNext) {
            draft.pageIndex = controlledPageCount;
          }
          return draft;
        })
      }
      disabled={!hasNext}
      className="page-link"
      aria-label="Last"
    >
      <span aria-hidden="true" className="bi-chevron-double-right"></span>
    </button>
  );

  return (
    <div className="table-pagination-wrap d-flex justify-content-between align-items-center">
      <span className="table-pagination-label">{`Showing ${startCount} to ${page?.pageSize} of ${totalCount} entries`}</span>
      <div>
        <Pagination size="md">
          <PaginationItem>
            <PaginationLink first tag={firstDoubleArrow} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous tag={previousArrow} />
          </PaginationItem>
          {debouncedPages.map((p) => {
            return (
              <PaginationItem
                key={`page_${p}`}
                active={pageIndex === p}
                onClick={() =>
                  setPage((draft) => {
                    draft.pageIndex = p;
                    return draft;
                  })
                }
              >
                <PaginationLink>{p}</PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem>
            <PaginationLink next tag={nextArrow} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last tag={lastDoubleArrow} />
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
};
