import { useQuery } from "react-query";
import { GET_BATCHES, getBatches } from "..";
import { useImmer } from "use-immer";

export const useBatchQuery = ({ enabled = false }) => {
  const [page, setPage] = useImmer({
    pageIndex: 1,
    pageSize: 50,
    firstName: "",
    status: null,
    startDate: null,
    endDate: null,
  });
  return {
    query: useQuery([GET_BATCHES, page], () => getBatches(page), {
      enabled,
    }),
    page,
    setPage,
  };
};
