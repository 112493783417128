import React from "react";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { Buffer } from "buffer";
import { registerLicense } from "@syncfusion/ej2-base";
import { useParams } from "react-router-dom";
registerLicense(
  //"Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS39QdkJgW39cdH1UTw==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdERgWXhdcnRXQ2NZWA==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiWn9Zc3ZVRGVZU00=;MTExOTM0MUAzMjMwMmUzNDJlMzBNNXFpZ1dqKzJmQ09GRXh6SHk0QVhMQ0o2Vk9jRFovS0hlbmN2R295MFZnPQ==;MTExOTM0MkAzMjMwMmUzNDJlMzBFWlRsOVgzYWhIaUMxbENZZ3h5MVZXdDBQcUV6YlRUTCtwRTV6YVgwc2FNPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhWHxed3dXRmJaUkF/;MTExOTM0NEAzMjMwMmUzNDJlMzBsWFpuVGk2dEh2S1VvRko0dmoxUTlxNWtKdE5WK0lLb1ZveXd0YzZoVFBnPQ==;MTExOTM0NUAzMjMwMmUzNDJlMzBwQjYxRU5PeG1BOC9Sc0R2dlk2YTVjRkhLVlpNZ0R4Y1NKSmxwZnJqUVdrPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiWn9Zc3ZVRGdeV00=;MTExOTM0N0AzMjMwMmUzNDJlMzBXZ2wxYmRQbnFoOEp2eFdiZlZyQlZiaks4djk2SEZ1bjRCcGp3MjlSV0swPQ==;MTExOTM0OEAzMjMwMmUzNDJlMzBFQ3RhOWNUZ2dSS3V4T3dwTXdSMUhic3hlMG5mNlF1RXdPaDcrWW5QQTc4PQ==;MTExOTM0OUAzMjMwMmUzNDJlMzBsWFpuVGk2dEh2S1VvRko0dmoxUTlxNWtKdE5WK0lLb1ZveXd0YzZoVFBnPQ=="
  "Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfeXRURmBZWUFxWEI="
  );

export const PDFViewer = (props) => {
  const { file } = useParams();
  let fileName = Buffer.from(file, "base64").toString("utf-8");
  return (
    <div>
      {" "}
      <PdfViewerComponent
        id="container"
        toolbarSettings={{
          showTooltip: true,
          toolbarItems: [
            "PageNavigationTool",
            "MagnificationTool",
            "SearchOption",
            "PanTool",
          ],
        }}
        documentPath={fileName}
        serviceUrl={`${process.env.REACT_APP_BASE_URL}api/pdfviewer`}
        style={{ height: "100vh" }}
      >
        <Inject
          services={[
            Toolbar,
            Navigation,
            // ThumbnailView,
            TextSearch,
            Magnification,
          ]}
        />
      </PdfViewerComponent>
    </div>
  );
};
