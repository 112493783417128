import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login, ForgotPassword, ResetPassword, Dashboard, GeneratePassword } from ".";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppScope, SidebarScope } from "master";
import { ProtectedRoute } from "shared/components/ProtectedRoute";
import { PDFViewer } from "modules";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export function Master() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppScope>
        <BrowserRouter basename="">
          <Routes>
            <Route exact element={<Login />} path={"/"} />
            <Route exact element={<ForgotPassword />} path={"/forgotPassword"} />
            <Route element={<GeneratePassword />} path={"/invitation"} />
            <Route exact element={<ResetPassword />} path={"/auth/reset"} />
            <Route element={<PDFViewer />} path={"/content/:file"} />
            <Route
              exact
              element={
                <SidebarScope>
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                </SidebarScope>
              }
              path={"/*"}
            />
            <Route element={<Navigate to="/login" />} path={"/"} />
          </Routes>
        </BrowserRouter>
      </AppScope>
    </QueryClientProvider>
  );
}
