import { Route, Routes } from "react-router-dom";
import { ContentList } from "./containers";

export const Content = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ContentList />} />
      </Routes>
    </>
  );
};
