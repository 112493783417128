import React from "react";
import { createElement, registerLicense } from "@syncfusion/ej2-base";
import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  QuickToolbar,
  Table,
} from "@syncfusion/ej2-react-richtexteditor";
import { ToolbarType } from "@syncfusion/ej2-react-richtexteditor";
import "../styles/syncFusionEditor.styles.css";

const EDITOR_KEY =
  "Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS39VdkNhXH5ccH1dRQ==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdERlWXlcdXVXR2FbUg==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiX39YcnFURGFfUkc=;MTE0MzAxNEAzMjMwMmUzNDJlMzBGWEoxQjdiYnF0aE9VUkQ2V2E0akk5QWlYMkNQRHhFWks1TWJqUmZJUXZnPQ==;MTE0MzAxNUAzMjMwMmUzNDJlMzBHZDRxRjM3czZUbmxNTzN3YmQzTllrWDhVRmovOEI2SnV3Y2MzTzkvSkJZPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhWHlednZQR2JfUEx1;MTE0MzAxN0AzMjMwMmUzNDJlMzBXNmVsWTI4RGRLeDhjZDBFaEkvVi9KRHBZODUvd0w0OHRxeUFsSE9reUowPQ==;MTE0MzAxOEAzMjMwMmUzNDJlMzBiQTF1TlN3UXBqTDBaQVo1WXJVaEg2cEV3dUNyYk1NcmdyMjRXSHJJa3VBPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiX39YcnFURGRYU0A=;MTE0MzAyMEAzMjMwMmUzNDJlMzBsaWljVEFmNkc4Z3hqQVFMOTU5dmJzckVNaG1jQWRCSGtsdWY3QUZiSHpVPQ==;MTE0MzAyMUAzMjMwMmUzNDJlMzBPVTVZa0FQS0EwTDN6aWwvdEZ4UEpiVkdTVHhGZmVyLzBqWWg5WFBBaVg0PQ==;MTE0MzAyMkAzMjMwMmUzNDJlMzBXNmVsWTI4RGRLeDhjZDBFaEkvVi9KRHBZODUvd0w0OHRxeUFsSE9reUowPQ==";

registerLicense(EDITOR_KEY);

export const SyncfusionEditor = ({ value, disabled = false, placeholder, onChange }) => {
  let rteObj;

  // Rich Text Editor items list
  const items = [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "NumberFormatList",
    "BulletFormatList",
    "Outdent",
    "Indent",
    "SuperScript",
    "SubScript",
    "|",
    "CreateTable",
    "CreateLink",
    "Image",
    "|",
    "ClearFormat",
    "SourceCode",
    "|",
    "Undo",
    "Redo",
  ];

  const quickToolbarSettings = {
    table: [
      "TableHeader",
      "TableRows",
      "TableColumns",
      "TableCell",
      "-",
      "BackgroundColor",
      "TableRemove",
      "TableCellVerticalAlign",
    ],
  };

  //Rich Text Editor ToolbarSettings
  const toolbarSettings = {
    items,
    type: ToolbarType.MultiRow,
    enableFloating: false,
  };

  let myCodeMirror;

  function mirrorConversion(e) {
    const textArea = rteObj.contentModule.getEditPanel();
    let id = rteObj.getID() + "mirror-view";
    let mirrorView = rteObj.element.querySelector("#" + id);
    let charCount = rteObj.element.querySelector(".e-rte-character-count");
    if (e.targetItem === "Preview") {
      textArea.style.display = "block";
      mirrorView.style.display = "none";
      textArea.innerHTML = myCodeMirror.getValue();
      charCount.style.display = "block";
    } else {
      if (!mirrorView) {
        mirrorView = createElement("div", { className: "e-content" });
        mirrorView.id = id;
        textArea.parentNode.appendChild(mirrorView);
      } else {
        mirrorView.innerHTML = "";
      }
      textArea.style.display = "none";
      mirrorView.style.display = "block";
      charCount.style.display = "none";
    }
  }

  function actionCompleteHandler(e) {
    if (e.targetItem && (e.targetItem === "SourceCode" || e.targetItem === "Preview")) {
      rteObj.sourceCodeModule.getPanel().style.display = "none";
      mirrorConversion(e);
    } else {
      setTimeout(() => {
        rteObj.toolbarModule.refreshToolbarOverflow();
      }, 1000);
    }
  }

  return (
    <RichTextEditorComponent
      change={onChange}
      value={value}
      id="toolsRTE"
      ref={(richtexteditor) => {
        rteObj = richtexteditor;
      }}
      insertImageSettings={{
        saveFormat: "Base64",
      }}
      showCharCount={false}
      actionComplete={actionCompleteHandler.bind(this)}
      toolbarSettings={toolbarSettings}
      quickToolbarSettings={quickToolbarSettings}
      disabled={disabled}
      placeholder={placeholder}
    >
      <Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]} />
    </RichTextEditorComponent>
  );
};
