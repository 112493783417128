import React from "react";
import "../styles/customDropdown.style.css";

export const CustomSelectDropdown = ({ options, label, selected, onSelect }) => {
  return (
    <select className="custom-select-drop" onChange={onSelect} value={selected}>
      <option value="">{label}</option>
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};
