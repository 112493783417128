import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useStudentQuery } from "..";

export const useStudent = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { batchId } = useParams();

  const { query: studentQuery, page, setPage } = useStudentQuery({ enabled: true, batchId });

  return { studentQuery, page, setPage };
};
