import React from "react";
import { useImmer } from "use-immer";
import { generatePassword } from "..";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorMessage } from "utils";

export const useGeneratePassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");
  const emailAddress = searchParams.get("email");

  const [generatePasswordState, setGeneratePasswordState] = useImmer({
    credential: { emailAddress: "", password: "", confirmPassword: "", token: "" },
    isValidate: false,
  });

  React.useEffect(() => {
    if (token && emailAddress) {
      setGeneratePasswordState((draft) => {
        draft.credential.token = token;
        draft.credential.emailAddress = emailAddress;
        return draft;
      });
    }
  }, [token, emailAddress]);

  console.log(generatePasswordState);

  const generatePasswordAuth = useMutation(generatePassword, {
    onSuccess: (data) => {
      setGeneratePasswordState((draft) => {
        draft.isValidate = true;
      });
      if (data) {
        localStorage.setItem("localData", JSON.stringify(data));
        // navigate("/", { replace: true });
        window.location.href = "/";
      }
    },
    onError: (data) => {
      errorMessage(data.response.data.message);
      setGeneratePasswordState((draft) => {
        draft.credential = {
          emailAddress: "",
          password: "",
          confirmPassword: "",
          token: "",
        };
        return draft;
      });
      navigate("/", { replace: true });
    },
    onSettled: () => {},
  });

  return {
    generatePasswordAuth,
    generatePasswordState,
    setGeneratePasswordState,
  };
};
