import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";

export const ImageVideoModal = ({ imageVideoModalOperation, isImageVideoModal, url, size }) => {
  return (
    <Modal isOpen={isImageVideoModal} toggle={imageVideoModalOperation} centered={true} size={size}>
      <ModalHeader toggle={imageVideoModalOperation}></ModalHeader>
      <ModalBody>
        <ReactPlayer width="100%" height="350px" controls url={url} />
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={imageVideoModalOperation}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
