import { Footer, SideBar, Header } from "..";
import { Routes, Route } from "react-router-dom";
import { Report, Content } from "modules";

export function Dashboard() {
  return (
    <div id="app">
      <SideBar />
      <div id="main" className="layout-navbar">
        <Header />
        <div id="main-content">
          <Routes>
            <Route element={<Report />} path={"/report/*"} />
            <Route element={<Content />} path={"/content/*"} />
          </Routes>
          <Footer />
        </div>
      </div>
    </div>
  );
}
