import { useQuery } from "react-query";
import { GET_STUDENTS, getStudentList } from "..";
import { useImmer } from "use-immer";

export const useStudentQuery = ({ enabled = false, batchId }) => {
  const [page, setPage] = useImmer({
    pageIndex: 1,
    pageSize: 50,
    firstName: "",
    batchId,
  });
  return {
    query: useQuery([GET_STUDENTS, page], () => getStudentList(page), {
      enabled,
    }),
    page,
    setPage,
  };
};
