import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useBatchQuery } from "..";

export const useBatch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { query: batchQuery, page, setPage } = useBatchQuery({ enabled: true });

  const goToDetails = (batchId) => {
    navigate(`${location.pathname}/${batchId}`);
  };

  return { batchQuery, page, setPage, goToDetails };
};
