import { useStudent } from "../hooks";
import { PageSection, ContentLayoutV2, PaginationTableLayout, StatusBadge } from "shared";
import moment from "moment";

export const StudentList = () => {
  const { studentQuery, page, setPage } = useStudent();

  const { data, isLoading } = studentQuery;
  const { data: students, totalPages, hasNext, hasPrevious, totalCount } = !isLoading && data;

  const StatusIndicator = ({ value }) => {
    return <StatusBadge value={value} />;
  };

  const dateFormatter = ({ value }) => {
    return value !== null && value !== "" && moment(value).format("d MMM YYYY hh:mm a");
  };

  const ConcatenateName = ({ row }) => {
    return `${row.original.firstName} ${row.original.lastName}`;
  };

  const columns = [
    {
      Header: "Reg.Number",
      accessor: "registrationId",
    },
    {
      Header: "Student Name",
      accessor: "studentId",
      Cell: ConcatenateName,
    },
    {
      Header: "Activation Code",
      accessor: "activationCode",
    },
    {
      Header: "Staus",
      accessor: "status",
      Cell: StatusIndicator,
    },
    {
      Header: "Activation Date",
      accessor: "localActivationDate",
      // Cell: dateFormatter,
    },
  ];

  let breadcrumb = [];
  if (students && students.length > 0) {
    breadcrumb.push({ label: students[0].batchName });
  }

  return (
    <ContentLayoutV2 title={"Batch"} subtitle={"List"} breadcrumb={breadcrumb}>
      <PageSection>
        <div className="card-header d-flex align-items-center justify-content-between border-bottom mb-3">
          <h3 className="card-title mb-0">Reports</h3>
          <div className="mb-1 d-flex align-items-center justify-content-end  "></div>
        </div>
        <div className="card-body">
          <PaginationTableLayout
            columns={columns}
            data={students ? students : []}
            controlledPageCount={totalPages}
            controlledpageNo={page.pageIndex}
            controlledpageSize={page.pageSize}
            controlledFilterValue={page.firstName}
            setPage={setPage}
            page={page}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            totalCount={totalCount}
          />
        </div>
      </PageSection>
    </ContentLayoutV2>
  );
};
