import { Routes, Route } from "react-router-dom";
import { BatchList, StudentList } from ".";

export const Report = () => {
  return (
    <Routes>
      <Route path="/" element={<BatchList />} />
      <Route path="/:batchId" element={<StudentList />} />
    </Routes>
  );
};
