import { Axios } from "utils";

export const getStudentList = async (pagination) => {
  const { pageIndex: pageNo, pageSize, batchId: batchid } = pagination;
  let query = `pageNo=${pageNo}&pageSize=${pageSize}`;
  const res = await Axios.get(`/CambridgeCourse/studentlist/${batchid}?${query}`);
  return res.data;
};

export const getBatches = async (filter) => {
  const { firstName: batchName, endDate, startDate, pageIndex: pageNo, pageSize, status } = filter;
  let query = `pageNo=${pageNo}&pageSize=${pageSize}`;
  query = batchName ? query + `&BatchName=${batchName}` : query;
  query = endDate ? query + `&EndDATE=${endDate}` : query;
  query = startDate ? query + `&StartDate=${startDate}` : query;
  query = status ? query + `&BatchStatus=${status}` : query;
  const res = await Axios.get(`/CambridgeCourse?${query}`);
  return res.data;
};
