import { Axios } from "utils";

export const getContent = async (contentID) => {
  const res = await Axios.get(`/CambridgeCourse/content/${contentID}`);
  return res.data;
};

export const getContentList = async (contentListId) => {
  const res = await Axios.get(`/CambridgeCourse/contentlist/${contentListId}`);
  return res.data;
};

export const addCambridgeCourseContent = async ({
  courseId,
  title,
  fileName,
  contentAttachment,
}) => {
  const res = await Axios.post(
    "/CambridgeCourse",
    {
      courseId,
      title,
      fileName,
      contentAttachment: null,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const deteleCambridgeCourseContent = async (courseContentId) => {
  const res = await Axios.delete(`/CambridgeCourse/${courseContentId}`);
  return res.data;
};
