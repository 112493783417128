import { Link } from "react-router-dom";

export const BreadcrumbV2 = ({ breadcrumb }) => {
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-header float-start">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/report">Report</Link>
        </li>
        {breadcrumb.map((item, idx) => {
          if (breadcrumb.length <= 1) {
            return (
              <li key={idx} className="breadcrumb-item active" aria-current="page">
                {item.label}
              </li>
            );
          }
          return (
            <li key={idx} className={`${item.location ? "" : "active"} breadcrumb-item`}>
              {item.location ? <Link to={item.location}>{item.label}</Link> : item.label}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
