import React, { useEffect, useRef, forwardRef } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { useImmer } from "use-immer";
import { FormGroup } from "reactstrap";
import InputControl from "./InputControl";
import { TablePaginationControlled } from "../components/TablePaginationControlled";
import "../styles/tableLayout.styles.css";

export const PaginationTableLayout = (props) => {
  const {
    page,
    columns,
    data,
    controlledPageCount,
    controlledpageNo,
    controlledpageSize,
    controlledFilterValue,
    setPage,
    hasNext,
    hasPrevious,
    totalCount,
  } = props;
  const [pageOptions, setPageOptions] = useImmer([]);

  React.useEffect(() => {
    setPageOptions((draft) => {
      draft = [...Array(controlledPageCount).keys()].map((x) => ++x);
      return draft;
    });
  }, [controlledPageCount]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    globalFilteredRows,
    state: { pageIndex, pageSize, firstName },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: controlledpageNo,
        pageSize: controlledpageSize,
        firstName: controlledFilterValue,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-sm-2">
          <div className="table-select-element">
            <select
              className="form-control"
              value={controlledpageSize}
              onChange={(e) => {
                setPage((draft) => {
                  draft.pageSize = Number(e.target.value);
                  draft.pageIndex = 1;
                  return draft;
                });
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-3">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setPage={setPage}
            controlledFilterValue={controlledFilterValue}
            ref={inputRef}
          />
        </div>
      </div>
      <div className="table-responsive table-styles">
        <table {...getTableProps()} className="table table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={column.Header === "Actions" ? "tbl-actions-th" : null}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {preGlobalFilteredRows.length > 0 && globalFilteredRows.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={cell.column.Header === "Actions" ? "tbl-actions-td" : null}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="20">
                  <div className="text-center border-0">No data available in table</div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <TablePaginationControlled
        setPage={setPage}
        page={page}
        pageIndex={pageIndex}
        controlledPageCount={controlledPageCount}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        pageOptions={pageOptions}
        pageSize={pageSize}
        totalCount={totalCount}
      />
    </>
  );
};

// GLOBAL FILTER

const GlobalFilter = forwardRef((props, ref) => {
  const { preGlobalFilteredRows, setPage, controlledFilterValue } = props;

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useImmer(controlledFilterValue);

  const onChange = useAsyncDebounce((value) => {
    setPage((draft) => {
      draft.firstName = value || "";
      draft.pageIndex = 1;
      return draft;
    });
  }, 500);

  return (
    <span className="table-search-field-element">
      <FormGroup>
        <InputControl
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          ref={ref}
          placeholder={`Search ${count} records...`}
        />
      </FormGroup>
    </span>
  );
});
